import React, { useState , useContext } from "react";
import { ImgLogo, GoogleIcon, AppleIcon, StarIcon, EyeIcon } from "../../assets";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { AppRoute } from "../../routes";


interface LoginFormData {
  email: string;
  password: string;
}



export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<LoginFormData>({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const login = useContext(AppContext)?.login;


  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault();
    console.log(formData);
    if (formData.email !== "myzim" || formData.password !== "pqWOu4G") {
      console.log("Invalid email or password");
    setError("Invalid email or password");
    return;
    }
    
    login?.(formData.email, formData.password);
      setTimeout(() => {
        navigate(AppRoute.Dashboard);
      }, 2000);
      return;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen overflow-x-hidden text-white font-poppins">
      <div className="flex w-full md:w-1/2 h-full">
        <div className="border overflow-hidden flex flex-col mx-3 mt-3 rounded-[1.5rem] bg-gray-200 w-full h-[calc(100vh-30px)] backdrop-blur-md">
          <nav className="text-center md:text-left ml-[2.5rem] mt-[2.5rem]">
            <ImgLogo data-id="nav-logo" className="w-[9.75rem] h-[2rem]" />
          </nav>

          <div className="flex flex-grow flex-col justify-center items-center ">
            <div className="flex flex-col">
            <StarIcon className="top-[12rem] w-[2.5rem] border-[2px] border-stroked h-[2.5rem] mx-auto bg-primary-inside rounded-[1rem] p-[0.75rem]" />
            <p className="text-text-color text-[1.7rem] md:text-[2.5rem] text-center mb-[10rem]">
              Continue with AI-powered <br />
              insights and support.
            </p>
            </div>
           
          </div>
          <div className="absolute bottom-[0rem] min-h-[17.4375rem] w-[17.4375rem] rounded-full bg-stroked/30 blur-[400px]"></div>
        </div>
      </div>

      {/* right section */}

      <div className="flex justify-center items-center md:w-1/2 h-full">
        <div className="flex flex-col gap-[1.5rem] pt-10">
          <div>
            <h1>Welcome Back!</h1>
            <p className="text-secondary-text tracking-[-0.03rem]">
              Login to continue where you left off.
            </p>
          </div>

          <form className="border rounded-[1.5rem] h-full w-full" onSubmit={handleFormSubmit}>
            <div className="flex flex-col relative">
              <div className="flex flex-col gap-[0.25rem] mb-[1.25rem] relative">
                <label htmlFor="email" className="tracking-[-0.04rem]">
                  Email
                </label>
                <input
                // change type to email
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  onChange={handleFormChange}
                  value={formData.email}
                  className={`bg-gray-200  text-secondary-text py-[0.625rem] px-[1rem] rounded-[0.625rem] focus:outline-none focus:border-stroked ${error ? "border border-warning" : "border border-transparent"} ${formData.email && "text-text-color"}`}
                />
                {error && (
                  <p className="absolute bottom-[-1.2rem] right-0 text-red-500 text-[0.75rem] m-0 p-0 tracking-[-0.015rem]">{error}</p>
                )}
              </div>
              <div
                data-id="right-glow"
                className="[filter:blur(896.4px)] absolute bg-primary-inside/50 w-[17.438rem] h-[20.75rem] z-[-2] top-0 right-[5rem]"
              />

              <div className="flex flex-col w-full gap-[0.25rem] relative">
                <label htmlFor="password">Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Enter your password"
                  name="password"
                  onChange={handleFormChange}
                  value={formData.password}
                  className={`bg-gray-200  text-secondary-text py-[0.625rem] px-[1rem] rounded-[0.625rem] focus:outline-none focus:border-stroked ${error ? "border border-warning" : "border border-transparent"}`}
                />
                <EyeIcon
                  className="w-[1rem] h-[1rem] absolute right-[1rem] bottom-[0.5rem] hover:cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
                 {error && (
                  <p className="absolute bottom-[-1.2rem] right-0 text-red-500 text-[0.75rem] m-0 p-0 tracking-[-0.015rem]">{error}</p>
                )}
              </div>

              <p className="self-end text-[#6381FC] text-[1rem] tracking-[-0.02rem] hover:cursor-pointer"
              onClick={() => navigate(AppRoute.ForgetPassword)}
              >
                Forget Password?
              </p>
            </div>

            <div className="flex flex-col w-full my-[1.5rem]">
              <button
                type="submit"
                className="flex justify-center items-center gap-[0.25rem] border rounded-[6.25rem] text-white font-poppins border-[#6381FC] h-[3rem] pt-[0.5rem] pr-[1.25rem] pb-[0.5rem] pl-[0.875rem] bg-[#292749] hover:cursor-pointer"
              >
                Login
              </button>
            </div>

            <div className="w-full">
              <div className="flex justify-center items-center">
                <hr className="h-[0.1rem] my-[1.5rem] bg-gradient-to-r from-[#1D1E2136] to-[#24262C] w-1/3" />
                <span className="text-secondary-text text-[0.875rem] tracking-[-0.0175rem]">
                  Continue with
                </span>
                <hr className="h-[0.1rem] my-[1.5rem] bg-gradient-to-l from-[#1D1E2136] to-[#24262C] w-1/3" />
              </div>

              <div className="flex flex-col md:flex-row gap-[1rem] justify-between">
                <button className="flex text-text-color justify-center items-center rounded-[6.25rem] pt-[0.8125rem] pr-[3.685rem] pb-[0.9375rem] pl-[3.6875rem] bg-[rgba(255,255,255,0.08)]">
                  <GoogleIcon className="w-[1.5rem] h-[1.5rem]" />
                  <span className="mx-2">Google</span>
                </button>

                <button className="flex text-text-color justify-center items-center rounded-[6.25rem] pt-[0.8125rem] pr-[3.685rem] pb-[0.9375rem] pl-[3.6875rem] bg-[rgba(255,255,255,0.08)]">
                  <AppleIcon className="w-[1.5rem] h-[1.5rem]" />
                  <span className="mx-2 tracking-[-0.0225rem]">Apple</span>
                </button>
              </div>

              <p className="text-[#707178] text-[0.875rem] text-center mt-[1.5rem]">
                Didn't have an account?{" "}
                <span
                  className="text-[#6381FC] hover:cursor-pointer"
                  onClick={() => navigate(AppRoute.Signup)}
                >
                  &nbsp; Signup
                </span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};