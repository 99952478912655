// src/context/AppContext.tsx
import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { useLocalStorage } from '../hooks/use-local-storage';

interface AppContextProps {
  city: string;
  document: string;
  questionSections: QuestionSection[];
  documentTitle: string;
  generatedMarkdown: string;
  formData: { [key: number]: { [questionIndex: number]: string | boolean } };
  currentStep: number;
  user: User | null;
  storedDocs: Document[];
  isAuthenticated: boolean;
  setCity: (city: string) => void;
  setDocument: (document: string) => void;
  setStoredDocs: (documents: Document[]) => void;
  setGeneratedMarkdown: (generatedMarkdown: string) => void;
  setQuestionSections: (sections: QuestionSection[]) => void;
  setDocumentTitle: (title: string) => void;
  setFormData: React.Dispatch<React.SetStateAction<{ [key: number]: { [questionIndex: number]: string | boolean } }>>;
  setCurrentStep: (currentStep: number) => void;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string, username: string) => Promise<void>;
  sendPasswordResetEmail?: (email: string) => Promise<void>;
  logout: () => void;
}

interface Question {
  question: string;
  type: "textinput" | "textarea" | "checkbox";
  placeholder: string;
}

interface Document{
  id: string;
  city: string;
  document: string;
  documentTitle: string;
  questionSections: QuestionSection[];
  generatedMarkdown: string;
  formData: { [key: number]: { [questionIndex: number]: string | boolean } };
}
interface QuestionSection {
  sectionTitle: string;
  questions: Question[];
}

interface User {
  id: string;
  email: string;
  username: string;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

const AppProvider = ({ children }: { children: ReactNode }) => {
  const [city, setCity] = useLocalStorage<string>('city', '');
  const [document, setDocument] = useLocalStorage<string>('document', '');
  const [currentStep, setCurrentStep] = useLocalStorage<number>('currentStep', 0);
  const [questionSections, setQuestionSections] = useLocalStorage<QuestionSection[]>('questionSections', []);
  const [documentTitle, setDocumentTitle] = useLocalStorage<string>('documentTitle', '');
  const [generatedMarkdown, setGeneratedMarkdown] = useLocalStorage<string>('generatedMarkdown', '');
  const [formData, setFormData] = useLocalStorage<{ [key: number]: { [questionIndex: number]: string | boolean } }>('formData', {});
  const [storedDocs, setStoredDocs] = useLocalStorage<Document[]>('storedDocs',[]);
  // Authentication state
  const [user, setUser] = useLocalStorage<User | null>('user', null);
  const isAuthenticated = Boolean(user);


  // Simulate login/signup API requests with local storage
  const login = async (email: string, password: string) => {
   

    const userData = { id: '1', email, username: 'SampleUser' };
    setUser(userData);
    localStorage.setItem('userToken', 'sample_token'); 
  };

  const signup = async (email: string, password: string, username: string) => {
    // Simulate user creation
    const userData = { id: '1', email, username };
    setUser(userData);
    localStorage.setItem('userToken', 'sample_token'); 
  };

  const logout = () => {
    console.log('Logging out');
    setUser(null);
    localStorage.removeItem('userToken');
    localStorage.removeItem('currentStep'); 
  };

  const sendPasswordResetEmail = async (email: string) => {
    // Simulate sending password reset email
    console.log(`Password reset email sent to: ${email}`);
  }

  // Reset currentStep on page unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('currentStep', '0');
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return (
    <AppContext.Provider
      value={{
        city,
        document,
        questionSections,
        documentTitle,
        formData,
        generatedMarkdown,
        currentStep,
        storedDocs,
        user,
        isAuthenticated,
        setCity,
        setDocument,
        setQuestionSections,
        setDocumentTitle,
        setFormData,
        setGeneratedMarkdown,
        setStoredDocs,
        setCurrentStep,
        sendPasswordResetEmail,
        login,
        signup,
        logout,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
