import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Markdown from "react-markdown";
import {
  ImgGenerate,
  ImgLineHorizontal,
  ImgLogo,
  PlusIcon,
  DashMenuIcon,
  ExportIcon,
  EditIcon,
  DownloadIcon,
  DeleteIcon,
} from "../../assets/index";
import MenuIcon from '@mui/icons-material/Menu';
import { PercentageIndicator } from "../../components/progress/percentage-indicator.component";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { generateDocument } from "../../services/generate-document";
import DocumentPreview from "../../components/pdf/document-preview"; // Import the DocumentPreview component

interface Question {
  question: string;
  type: "textinput" | "textarea" | "checkbox";
  placeholder: string;
}

interface QuestionSection {
  sectionTitle: string;
  questions: Question[];
}

interface History {
  title: string;
  id: string;
}

const documents = [
  "Lease Agreement",
  "Purchase Agreement",
  "Non-Disclosure Agreement",
  "Partnership Agreement",
  "Service Level Agreement",
  "Consulting Agreement",
  "Sales Contract",
  "Employment Agreement",
];

export function DashboardPage() {
  const generatedDocumentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [newDocument, setNewDocument] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [generatedDocument, setGeneratedDocument] = useState("");
  const [selectedDocTitle, setSelectedDocTitle] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false); // State for sidebar visibility
  const [tempCity, setTempCity] = useState("");
  const [showDropdown, setShowDropdown] = useState<string | null>();
  const [history, setHistory] = useState<History[]>([]);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [tempDocument, setTempDocument] = useState("");
  const [selectedDocument, setSelectedDocument] = useState<string | null>(null); // State for selected document
  const [selectedMarkdown, setSelectedMarkdown] = useState<string | null>(null); // State for selected markdown
  const documentRef = useRef<HTMLDivElement | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null); // Ref for sidebar
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Ref for dropdown

  const appContext = useContext(AppContext);

  const {
    city,
    setCity,
    setDocument,
    setQuestionSections,
    setDocumentTitle,
    setFormData,
    isAuthenticated,
    user,
    logout,
    storedDocs,
    setStoredDocs,
  } = appContext!!;

  useEffect(() => {
    if (documentRef.current) {
      console.log("Document Ref", documentRef.current);
    }
  }, []);
  useEffect(() => {
    if (storedDocs.length > 0) {
      setHistory(
        storedDocs.map((doc) => {
          return {
            title: doc.document,
            id: doc.id, // Assuming doc has an id property
          };
        })
      );
    }
    console.log("history", history);
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (loading) {
      setLoadingPercentage(0); // Reset loading percentage
      interval = setInterval(() => {
        setLoadingPercentage((prev) => (prev >= 99 ? 99 : prev + 1)); // Increment by 1 up to 99%
      }, 60); // Update every 60ms
    } else {
      clearInterval(interval);
      setLoadingPercentage(0); // Set to 100% when loading is complete
    }

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [loading]);

  const handleCardClick = (item: string) => {
    setNewDocument("");
    setTempDocument(item);
    let selectedDoc = storedDocs.find((doc) => doc.id === item);
    console.log("Selected Document", selectedDoc);
    if (selectedDoc) {
      setSelectedMarkdown(selectedDoc.generatedMarkdown);
      setSelectedDocument(item);
      setSelectedDocTitle(selectedDoc.document);
    }

    console.log("Selected Document", selectedDocument);
  };

  const handleGenerate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    generateDocument({
      tempDocument,
      tempCity,
      isAuthenticated,
      user,
      setError,
      setDocument,
      setCity,
      setLoading,
      setGeneratedDocument,
      setDocumentTitle,
      setQuestionSections,
      setFormData,
      navigate,
    });

    // navigate("/document/form");
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(null);
        setHoveredItem(null);
      }

        if (
            sidebarRef.current &&
            !sidebarRef.current.contains(event.target as Node)
        ) {
            setSidebarOpen(false);
        }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDeleteForm = (item: string) => {
    let newStoredDocs = storedDocs.filter((doc) => doc.id !== item);
    setHistory(
      newStoredDocs.map((doc) => {
        return {
          title: doc.document,
          id: doc.id, // Assuming doc has an id property
        };
      })
    );
    setStoredDocs(newStoredDocs);
    setShowDropdown(null);
    setHoveredItem(null);
    setSelectedMarkdown(null);
  };

  return (
    <>
      <Helmet>
        <title>AI Frank | Home</title>
        <meta name="description" content="" />
      </Helmet>

      <div
        data-id="container"
        className="w-full flex flex-row h-screen  max-w-full overflow-x-hidden text-center text-[1.25rem] text-text-color font-poppins overflow-hidden "
      >
        {/* Toggle button for sidebar */}
        <div
          className={`md:hidden p-4  h-fit absolute top-4 left-4  z-50 ${sidebarOpen ? "hidden" : ""}`}
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <MenuIcon sx={{
            color: 'white',
            fontSize: 40
          }}
          onClick={() => setSidebarOpen(!sidebarOpen)}

          />
          
        </div>

        {/* side bar */}
         <div
            ref={sidebarRef}
          className={`md:bg-gray-500 min-h-[calc(100vh)] bg-primary-text border-r-4 pt-[2.5rem] md:min-w-[calc(13.54vw)] flex flex-col items-center px-[1.25rem] border-r-stroked transition-transform duration-300 ${
            sidebarOpen ? "translate-x-0 " : "-translate-x-full"
          } md:translate-x-0 fixed md:relative z-50 md:z-auto`}
        >{/* <div
          data-id="top-glow"
        className = "[filter:blur(500px)] absolute rounded-[50%] bg-primary-inside  border-stroked border-[1px] border-solid box-border w-[50rem] h-[30rem] mb-[-120px] mx-auto mt-[-20rem]"
        /> */}
          <div className="flex w-full flex-col gap-[1.5rem]">
            <nav className="">
              <ImgLogo data-id="nav-logo" className="w-[9.75rem] h-[2rem]" />
            </nav>
            <hr className="w-full  bg-gray-600 border-gray-600 border-[1px] border-solid"></hr>

            <button
              className="flex p-[0.5rem] px-[1.5rem] justify-center items-center gap-[0.125rem] self-stretch rounded-[6.25rem] border border-stroked bg-[rgba(99,129,252,0.12)]
        text-stroked font-poppins text-[0.875rem] font-normal  leading-normal tracking-[-0.035rem] hover:cursor-pointer
        "
              onClick={() => {
                setNewDocument("generateRandomId");
                setSelectedDocument(null);
                setSelectedMarkdown(null);
              }}
            >
              <div className="flex items-center justify-center gap-[0.25rem]">
                <PlusIcon className="w-[1rem] h-[1rem]" />
                New Document
              </div>
            </button>

            <div className="flex flex-col gap-[1.5rem]">
              <p className="text-secondary-text m-0 p-0 font-poppins text-[0.875rem] text-start  font-medium leading-normal tracking-[-0.035rem]">
                Today
              </p>
              <div className="flex flex-col  gap-[0.5rem]">
                {history.map((item, idx) => (
                  <div className="relative self-stretch" key={item.id}>
                    <div
                      className={`relative flex p-[0.625rem]  items-center h-[1.5rem]  text-start  gap-[0.625rem] self-stretch rounded-[0.5rem]  border border-stroked transition-colors duration-300
                        ${
                          hoveredItem === idx
                            ? "border-r-4 border-r-stroked bg-gray-300 cursor-pointer "
                            : "border-r-4 border-transparent"
                        }
                        `}
                      onMouseEnter={() => setHoveredItem(idx)}
                      onMouseLeave={() => {
                        showDropdown !== item.id && setHoveredItem(null);
                      }}
                    >
                      <div className="flex items-center w-[3px] justify-center  ">
                        {hoveredItem === idx && (
                          <hr className="w-[3px] h-[1.5rem] bg-stroked rounded-xl"></hr>
                        )}
                      </div>

                      <div
                        onClick={() => handleCardClick(item.id)}
                        className="text-[0.875rem] px-2 text-text-color hover:border-r-4 border-r-stroked flex-grow font-poppins font-normal leading-normal tracking-[-0.035rem] break-words whitespace-nowrap overflow-hidden text-clip transition-all duration-300 ease-in-out"
                      >
                        {item.title}
                      </div>

                      <div className="w-[1rem] flex items-center justify-center ">
                        {hoveredItem === idx && (
                          <DashMenuIcon
                            className="w-[1rem] h-[1rem]"
                            onClick={() => {
                              setShowDropdown(item.id);
                            }}
                          ></DashMenuIcon>
                        )}
                      </div>
                    </div>
                    {showDropdown === item.id && (
                      <div
                        ref={dropdownRef}
                        className="absolute top-[2.5rem] right-[-2.5rem] flex flex-col gap-[0.5rem] bg-[#2A2A35] p-[0.5rem] px-[0.75rem] rounded-[0.5rem] z-20"
                      >
                        <div className="flex pt-[0.5rem] pr-[2rem] pb-[0.5rem] pl-[0.5625rem] items-center gap-[0.375rem] self-stretch border hover:border-stroked hover:bg-hover hover:cursor-pointer">
                          <EditIcon className="w-[1.25rem] h-[1.25rem]"></EditIcon>
                          <p className="text-text-color font-poppins text-[0.875rem] font-medium leading-normal tracking-[-0.0175rem] p-0 m-0">
                            Edit
                          </p>
                        </div>
                        <div className="flex pt-[0.5rem] pr-[2rem] pb-[0.5rem] pl-[0.5625rem] items-center gap-[0.375rem] self-stretch border hover:border-stroked hover:bg-hover hover:cursor-pointer">
                          <DownloadIcon className="w-[1.25rem] h-[1.25rem]"></DownloadIcon>
                          <p className="text-text-color font-poppins text-[0.875rem] font-medium leading-normal tracking-[-0.0175rem] p-0 m-0">
                            Download
                          </p>
                        </div>
                        <div
                          className="flex pt-[0.5rem] pr-[2rem] pb-[0.5rem] pl-[0.5625rem] items-center gap-[0.375rem] self-stretch border hover:border-stroked hover:bg-hover hover:cursor-pointer"
                          onClick={() => handleDeleteForm(item.id)}
                        >
                          <DeleteIcon className="w-[1.25rem] h-[1.25rem]"></DeleteIcon>
                          <p className="text-warning font-poppins text-[0.875rem] font-medium leading-normal tracking-[-0.0175rem] p-0 m-0">
                            Delete
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* main screen */}
        <div className="w-full  h-screen flex flex-col items-center justify-center gap-[3.5rem]  max-w-full overflow-x-hidden text-center  text-[1.25rem] text-text-color font-poppins overflow-hidden transition ">
          {!selectedMarkdown && (
            <div
              className={`flex  flex-col items-center    text-[1.125rem]   ${
                newDocument ? "animate-slide-up" : ""
              } `}
            >
              <div className="rounded-[30px] mt-20 md:m-0  bg-primary-inside border-stroked border-[1px] border-solid flex flex-row items-center justify-center py-[0.5rem] px-[2rem]">
                <div className="relative tracking-[-0.04em] font-medium">
                  AI For Documents
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-[2rem] md:text-[3.5rem] mt-[1rem]">
                <div className="self-stretch relative tracking-[-0.06em] font-medium">
                  <span>Generate</span>
                  <span className="text-white">{` `}</span>
                  <span className="text-transparent !bg-clip-text [background:linear-gradient(91.15deg,_#707178,_#484950)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">{`Legal `}</span>
                  <span>Documents</span>
                </div>
                <div className="self-stretch relative  text-[1.5rem] md:text-[1.25rem] tracking-[-0.04em] font-medium text-secondary-text mt-[0.5rem]">
                  Legal documents Personalized for your needs.
                </div>
              </div>
            </div>
          )}

          <div
            className={`max-w-[45rem] [backdrop-filter:blur(20px)] rounded-2xl bg-gray-500  border-gray-100 border-[1px] border-solid box-border flex 
               ${
                 newDocument ? "animate-slide-up" : "opacity-0 hidden"
               } flex-col items-center  justify-center mx-[1rem] md:mx-auto p-[1.5rem] gap-[1rem] text-[0.875rem]   text-stroked transition duration-500`}
          >
            {loading && (
              <PercentageIndicator
                show={loading}
                loadedPercentage={loadingPercentage}
                label="Generating Prerequisites"
                showPercent
              />
            )}

            <div
              className={`${
                loading ? "blur-md pointer-events-none " : ""
              }  max-w-[43.1875rem]  px-[1.5rem]   `}
            >
              <div className="relative tracking-[-0.04em] font-medium mb-4">
                Available in the US only
              </div>
              <form
                onSubmit={handleGenerate}
                className={`self-stretch  flex-col items-center justify-start gap-[1.5rem] text-left text-[1.125rem] text-text-color z-1 `}
              >
                <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem] ">
                  <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />
                  <div className="self-stretch flex flex-col md:flex-row items-center   overflow-hidden justify-start gap-[0.5rem] ">
                    <div className="max-w-[5.563rem] relative tracking-[-0.04em] font-medium flex items-center h-[2rem] shrink-0">
                      Generate
                    </div>
                    <div className="flex-1 w-[15rem] overflow-hidden rounded-lg bg-gray-200 flex flex-row items-center justify-start py-[0.625rem] px-[1rem] text-[1rem] text-secondary-text">
                      <input
                        value={tempDocument}
                        type="text"
                        onChange={(e) => {
                          setTempDocument(e.target.value);
                          setError("");
                        }}
                        className="font-medium font-poppins text-lg flex items-center h-[1.5rem] shrink-0 bg-transparent text-white outline-none"
                        placeholder="Document"
                      />
                    </div>
                    <div className="relative tracking-[-0.04em] font-medium flex items-center h-[2rem] shrink-0">
                      For
                    </div>
                    <div className="flex-1 w-[15rem] rounded-lg bg-gray-200 flex flex-row items-center justify-start py-[0.625rem] px-[1rem] text-[1rem] text-secondary-text">
                      <input
                        value={tempCity}
                        onChange={(e) => {
                          setTempCity(e.target.value);
                          setError("");
                        }}
                        className="font-medium font-poppins text-lg flex items-center h-[1.5rem] shrink-0 bg-transparent text-white outline-none"
                        placeholder="City"
                      />
                    </div>
                  </div>
                  <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />
                </div>
                <div className="self-stretch flex  items-center justify-center text-center text-[1rem]  ">
                  <div className="flex flex-col items-center justify-center gap-[0.375rem]">
                    <button
                      disabled={loading}
                      className="flex-1 rounded-81xl bg-primary-inside border-stroked border-[1px] border-solid overflow-hidden flex flex-row items-center justify-center py-[0.5rem] pl-[1.5rem] pr-[2rem] gap-[0.25rem] outline-none hover:bg-primary-inside/40 transition-colors duration-300 cursor-pointer"
                    >
                      <ImgGenerate className="w-[1.5rem] relative h-[1.5rem] overflow-hidden shrink-0" />
                      <div className="font-medium text-[1rem] text-white font-poppins">
                        {loading ? "Generating..." : "Generate Form"}
                      </div>
                    </button>

                    {/* not present in figma */}
                    {/* <div style={{ display: loading ? 'none' : 'flex' }} className="flex-1 rounded-81xl bg-gray-200 border-darkslategray border-[1px] border-solid overflow-hidden flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] text-white cursor-pointer border-solid border-[1px] border-primary-inside hover:bg-primary-inside/40 transition-colors duration-300" onClick={() => { setTempCity(''); setTempDocument('') }}>
                    <div className="relative tracking-[-0.04em] font-medium ">Clear</div>
                  </div> */}
                    {error && (
                      <div className="text-red-500 align-center">{error}</div>
                    )}
                  </div>

                  {/* <div className="hidden md:flex rounded-81xl bg-gray-200 border-darkslategray border-[1px] border-solid overflow-hidden flex-col items-center justify-center py-[0.5rem] px-[1.25rem]">
                  <div className="relative tracking-[-0.04em] font-medium">Share</div>
                </div> */}
                </div>
              </form>
            </div>
          </div>
          {selectedMarkdown && (
            <div className="min-h-[calc(100vh)] pt-[1.5rem] px-[2.5rem]">
              <div className="  w-full flex justify-between items-center">
                <h1 className="text-[var(--Text-Color,#FDFDFD)] font-poppins text-[2rem] font-semibold leading-normal tracking-[-0.08rem]">
                  {selectedDocTitle}
                </h1>
                <div className="flex items-center justify-center gap-x-[1.25rem]">
                  <button
                    className="flex bg-transparent text-white  "
                    onClick={() => {
                      console.log("Edit document");
                    }}
                  >
                    <div className="flex items-center justify-center gap-[0.25rem] text-[0.875rem]">
                      <EditIcon className="w-[1.375rem] h-[1.375rem]" />
                      Edit Document
                    </div>
                  </button>
                  <button
                    className="flex p-[0.5rem] px-[1.5rem] justify-center items-center gap-[0.125rem] self-stretch rounded-[6.25rem] border border-stroked bg-[rgba(99,129,252,0.12)]
                        text-stroked font-poppins text-[0.875rem] font-normal  leading-normal tracking-[-0.035rem] hover:cursor-pointer
                        "
                    onClick={() => {
                      console.log("Exporting document");
                    }}
                  >
                    <div className="flex items-center justify-center gap-[0.25rem]">
                      <ExportIcon className="w-[1rem] h-[1rem]" />
                      Export
                    </div>
                  </button>
                </div>
              </div>
              <hr className="w-full  bg-gray-200 border-gray-600 border-[1px] border-solid mb-[1.5rem]"></hr>
              <div className="max-h-[40rem] lg:max-h-[calc(100vh -10px)] overflow-y-scroll scrollbar-hide rounded-lg w-[100%]">
                <DocumentPreview
                  ref={documentRef}
                  markdownContent={selectedMarkdown}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
