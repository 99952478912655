import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { HomePage } from "./pages/home/home";
import { FormPage } from "./pages/form/form";
import { PdfPage } from "./pages/pdf/pdf";
import { LoginPage } from "./pages/login/login";
import { SignupPage } from "./pages/signup/signup";
import { DashboardPage } from "./pages/dashboard/dashboard";
import { ForgetPasswordPage } from "./pages/forget-password/forget-password";

export enum AppRoute {
  Home = "/",
  Form = "/documents/form",
  Dashboard = "/dashboard",
  Pdf = "/documents/pdf", 
  Login = "/login",
  Signup = "/signup",
  ForgetPassword = "/forget-password",
}

const router = createBrowserRouter(
  createRoutesFromElements([
    
    <Route path={AppRoute.Dashboard} element={<DashboardPage />} />,
    <Route path={AppRoute.Home} element={<HomePage />} />,
    <Route path={AppRoute.Form} element={<FormPage />} />,
    <Route path={AppRoute.Pdf} element={<PdfPage />} />,
    <Route path={AppRoute.Login} element={<LoginPage />} />,
    <Route path={AppRoute.ForgetPassword} element= {<ForgetPasswordPage/>} />,
    <Route path={AppRoute.Signup} element={<SignupPage />} />,
  ])
);

export const AppRoutes = () => <RouterProvider router={router} />;
