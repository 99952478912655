import { useContext, useEffect, useState } from "react";
import { ReactComponent as StepCompletedIcon } from '../../assets/img/form/step-completed.svg';
import { ReactComponent as StepPendingIcon } from '../../assets/img/form/step-pending.svg';
import { ReactComponent as ValidateIcon } from '../../assets/img/form/validate.svg';
import { ReactComponent as BuildDocumentIcon } from '../../assets/img/form/build-document.svg'
import { ReactComponent as PreparingDownloadDocumentIcon } from '../../assets/img/form/preparing-download-document.svg';
import { ReactComponent as ReadyDownloadDocumentIcon } from '../../assets/img/form/ready-download-document.svg';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

interface PreparingDocumentProps {
    show: boolean;
    loadedPercentage: number;
    loading?: boolean;
}

export function PreparingDocument({
    show,
    loadedPercentage = 0,
    loading = false,
}: PreparingDocumentProps) {

    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const { setCurrentStep } = appContext!!;

    if (!show) return null;

    const radius = 170;
    const circumference = 2 * Math.PI * 170;
    const angles = [30, 150, 270]; // Angles in degrees for the 3 image positions
    const imagePositions = angles.map((angle) => {
        const radians = (angle * Math.PI) / 180; // Convert angle to radians
        return {
            x: radius + radius * Math.cos(radians), // x position on the circle
            y: radius + radius * Math.sin(radians), // y position on the circle
        };
    });


    return (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-opacity-25 bg-gray-100 z-10 rounded-[24px] border-gray-100 border-[1px] border-solid box-border">
            <div className="relative w-[40rem] h-[40rem] md:w-190 md:h-190">
                <svg className="w-full h-full transform -rotate-90">
                    <circle
                        cx="50%"
                        cy="50%"
                        r={radius}
                        stroke="currentColor"
                        strokeWidth="6"
                        className="text-gray-300"
                        fill="transparent"
                    />
                    <circle
                        cx="50%"
                        cy="50%"
                        r={radius}
                        stroke="#6280FB"
                        strokeWidth="6"
                        strokeDasharray={circumference}
                        strokeDashoffset={circumference - (circumference * loadedPercentage) / 100}
                        className="text-blue-500 transition-all duration-200"
                        fill="transparent"
                    />
                </svg>

                {imagePositions.map((pos, index) => {
                    // Check if the step is completed based on loadedPercentage
                    const isCompleted = index === 2 || loadedPercentage >= (index + 1) * 33;
                    return (
                        <div
                            key={index}
                            className="absolute"
                            style={{
                                left: `calc(48% + ${pos.x - radius}px)`,
                                top: `calc(48% + ${pos.y - radius}px)`,
                            }}
                        >
                            {isCompleted ? (
                                <StepCompletedIcon width="25" height="25" />
                            ) : (
                                <StepPendingIcon width="25" height="25" />
                            )}
                        </div>
                    );
                })}
                {imagePositions.map((pos, index) => {
                    return (
                        <div
                            key={index}
                            className="hidden md:block absolute"
                            style={{
                                left: `calc(18% + ${pos.x - radius}px)`,
                                top: `calc(40% + ${pos.y - radius}px)`,
                            }}
                        >
                            {
                                index === 0 &&
                                <p className="mt-[5.5rem] ml-[10.5rem] text-lg">Building<br />Your Document</p>
                            }
                            {
                                index === 1 &&
                                <p className="mt-[5.5rem] ml-[6.5rem] text-lg">Preparing<br />for Download</p>
                            }
                            {
                                index === 2 &&
                                <p className="space-t-2 ml-[8.5rem] text-lg">Validating Input</p>
                            }
                        </div>
                    );
                })}
                {loadedPercentage < 34 && (
                    <div className="absolute inset-0 flex flex-col p-[4rem] items-center justify-center gap-2">
                        <div className="bg-[#31313D] w-12 h-10 pt-1 content-center rounded-lg border-solid border-[1px] border-stroked">
                            <ValidateIcon width="25" height="25" />
                        </div>
                        <span className="text-white text-lg">
                            Validating Input
                            <br />
                        </span>
                        <span className="text-secondary-text text-base px-[1rem] mx-[1rem]">
                            Verifying the details you've<br />provided to ensure accuracy.
                        </span>
                    </div>
                )}
                {loadedPercentage > 33 && loadedPercentage < 67 && (
                    <div className="absolute inset-0 flex flex-col p-[2rem] items-center justify-center gap-2">
                        <div className="bg-[#31313D] w-12 h-10 pt-1 content-center rounded-lg border-solid border-[1px] border-stroked">
                            <BuildDocumentIcon width="25" height="25" />
                        </div>
                        <span className="text-white text-lg">
                            Building Your Document
                            <br />
                        </span>
                        <span className="text-secondary-text text-base">
                            Generating your custom commercial<br />lease with the provided information.
                        </span>
                    </div>
                )}
                {loadedPercentage > 66 && loadedPercentage < 99 && (
                    <div className="absolute inset-0 flex flex-col p-[2rem] items-center justify-center gap-2">
                        <div className="bg-[#31313D] w-12 h-10 pt-1 content-center rounded-lg border-solid border-[1px] border-stroked">
                            <PreparingDownloadDocumentIcon width="25" height="25" />
                        </div>
                        <span className="text-white text-lg">
                            Preparing for Download
                            <br />
                        </span>
                        <span className="text-secondary-text text-base">
                            Final touches applied. Preparing<br />your lease for download.
                        </span>
                    </div>
                )}
                {loadedPercentage > 98 && !loading && (
                    <div
                        className="absolute inset-0 flex flex-col p-[1rem] items-center justify-center gap-2 cursor-pointer"
                        onClick={() => {
                            navigate('/documents/pdf');
                            setCurrentStep(0);
                        }}

                    >
                        <div className="bg-[#31313D] w-12 h-10 pt-1 content-center rounded-lg border-solid border-[1px] border-stroked">
                            <ReadyDownloadDocumentIcon width="25" height="25" />
                        </div>
                        <span className="text-white text-lg">
                            Ready to Download
                            <br />
                        </span>
                        <span className="text-secondary-text text-base">
                            Your lease is ready! Click <br />to download.
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}
