import React from 'react';
import { ReactComponent as DoneIcon } from '../../assets/img/progress/tick.svg';


interface Step {
    label: string;
    step: number;
}

interface ProgressStepsProps {
    steps: Step[];
    currentStep: number;
    onStepChange?: (step: number) => void;
    hideNavigation?: boolean;
    documentCompleted?: boolean;
    direction?: 'horizontal' | 'vertical';
}

const ProgressSteps: React.FC<ProgressStepsProps> = ({
    steps,
    currentStep,
    onStepChange,
    hideNavigation,
    documentCompleted,
    direction = 'horizontal',
}) => {
    const totalSteps = steps.length;

    const nextStep = () => {
        if (onStepChange && currentStep < totalSteps) {
            onStepChange(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (onStepChange && currentStep > 1) {
            onStepChange(currentStep - 1);
        }
    };

    const calculateProgressWidth = () => {
        return ((currentStep - 1) / (totalSteps - 1)) * 100 + '%';
    };

    const isHorizontal = direction === 'horizontal';

    return (
        <div className={`w-full mb-[1.5rem] lg:mb-0 max-w-xl mx-auto ${isHorizontal ? '' : 'flex flex-col'}`}>
            <div className={`relative flex ${isHorizontal ? 'items-center' : 'flex-col'}`}>


                {/* Background Line */}
                <div
                    className={`absolute ${isHorizontal ? 'left-0 top-1/2 w-full h-1' : 'top-0 left-5 h-full w-1'} bg-primary-inside ${isHorizontal ? '-translate-y-1/2' : '-translate-x-1/2'}`}
                ></div>

                {/* Dynamic Progress Line */}
                <div
                    className={`absolute ${isHorizontal ? 'left-0 top-1/2 h-1' : 'top-0 left-5 w-1'} bg-stroked transition-all duration-300 ease-in-out ${isHorizontal ? '-translate-y-1/2' : '-translate-x-1/2'}`}
                    style={{
                        [isHorizontal ? 'width' : 'height']: calculateProgressWidth(),
                    }}
                ></div>
                <div className={`gap-[2rem] md:gap-[3rem] flex ${isHorizontal ? 'flex-row' : 'flex-col'}`}>
                    {steps.map(({ step, label }) => (
                        <div
                            key={step}
                            className={`relative flex items-center ${isHorizontal ? 'flex-col' : 'flex-row'}`}
                        >
                            <div>
                                {/* Step Circle */}
                                <div
                                    className={`w-6 h-6 md:w-9 md:h-9 ml-[0.4rem] md:ml-0 rounded-full flex items-center border-solid border-2 bg-primary-inside self-center justify-center transition-colors duration-300 ease-in-out z-10
                                                ${currentStep >= step ? 'border-stroked' : 'border-[#383568]'} 
                                                ${isHorizontal ? '' : 'mr-0'}`}
                                >
                                    {currentStep === step && !documentCompleted &&
                                        <span className='p-1 md:p-2 bg-stroked rounded-full'></span>
                                    }
                                    {(currentStep > step || documentCompleted) &&
                                        <DoneIcon className='w-[100%] h-[80%]' />
                                    }
                                </div>
                            </div>
                            {/* Step Label */}
                            <span
                                className={`tracking-[-0.04em] text-base md:text-[1.1rem] font-small
                                            ${currentStep >= step ? 'text-white' : 'text-secondary-text'} 
                                            ${isHorizontal ? '' : 'ml-4'}`
                                }
                            >
                                {label}
                            </span>
                        </div>
                    ))}
                </div>
            </div>

            {/* Navigation Buttons */}
            {!hideNavigation && (
                <div className={`flex ${isHorizontal ? 'justify-between' : 'flex-col items-center'} mt-10`}>
                    <button
                        onClick={prevStep}
                        disabled={currentStep === 1}
                        className="px-4 py-2 mb-2 rounded bg-gray-300 text-gray-600 hover:bg-gray-400 disabled:bg-gray-200 disabled:text-gray-400"
                    >
                        Previous
                    </button>
                    <button
                        onClick={nextStep}
                        disabled={currentStep === totalSteps}
                        className="px-4 py-2 rounded bg-purple-600 text-white hover:bg-purple-700 disabled:bg-gray-200 disabled:text-gray-400"
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default ProgressSteps;
