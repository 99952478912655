import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Markdown from "react-markdown";
import { 
  ImgGenerate, 
  ImgLineHorizontal, 
  ImgLineLeft, 
  ImgLineRight, 
  ImgLogo, 
  BurstIcon 
} from "../../assets/index"
import { PercentageIndicator } from "../../components/progress/percentage-indicator.component";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { InfiniteMovingCards } from "../../components/ui/infinite-moving-cards";
import LogoutIcon from '@mui/icons-material/Logout';
import { AppRoute } from "../../routes";


interface Question {
  question: string;
  type: "textinput" | "textarea" | "checkbox";
  placeholder: string;
}

interface QuestionSection {
  sectionTitle: string;
  questions: Question[];
}

const documents = [
  "Lease Agreement",
  "Purchase Agreement",
  "Non-Disclosure Agreement",
  "Partnership Agreement",
  "Service Level Agreement",
  "Consulting Agreement",
  "Sales Contract",
  "Employment Agreement",
];

export function HomePage() {

  
  const generatedDocumentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [generatedDocument, setGeneratedDocument] = useState("");
  const [tempCity, setTempCity] = useState("");
  const [tempDocument, setTempDocument] = useState("");
  const appContext = useContext(AppContext);
  const [history, setHistory] = useState<string[]>([]);

  const {
    city,
    document,
    setCity,
    setDocument,
    setQuestionSections,
    setDocumentTitle,
    setFormData,
    storedDocs,
    isAuthenticated,
    user,
    logout,
  } = appContext!!;

  useEffect(() => {
    if (isAuthenticated) {
      navigate(AppRoute.Dashboard);
    }
  },[] );



  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (loading) {
      setLoadingPercentage(0); // Reset loading percentage
      interval = setInterval(() => {
        setLoadingPercentage((prev) => (prev >= 99 ? 99 : prev + 1)); // Increment by 1 up to 99%
      }, 60); // Update every 60ms
    } else {
      clearInterval(interval);
      setLoadingPercentage(0); // Set to 100% when loading is complete
    }

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [loading]);

  const handleCardClick = (item: string) => {
    console.log("I got clicked", item);
    setTempDocument(item);
  };

  const handleGenerate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isAuthenticated || !user) {
      navigate("/login");
      return;
    }


    setError("");

    if (tempDocument.trim() === "" || tempCity.trim() === "") {
      setError("Document and city are required");
      return;
    }
    setDocument(tempDocument);
    setCity(tempCity);
    const openAIKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!openAIKey) {
      console.error("OpenAI API key is not set");
      return;
    }

    setLoading(true);

    // const response = await fetch('https://api.openai.com/v1/chat/completions', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${openAIKey}`
    //   },
    //   body: JSON.stringify({
    //     model: 'gpt-4o-mini',
    //     response_format: { type: 'json_object' },
    //     messages: [
    //       { role: 'system', content: 'You are a helpful assistant.' },
    //       { role: 'user', content: `Generate a legal document for ${document} in ${city}. Return JSON with questions you require to complete the document. Format: [{"question": "question", "type": "type"}]. Type can be textinput or textarea.` }
    //     ],
    //     max_tokens: 1000
    //   })
    // });

    // const data = await response.json();

    // if (data.choices[0].message.content) {
    //   const q = JSON.parse(data.choices[0].message.content);
    //   if (q.questions) {
    //     setQuestions(q.questions);
    //   } else if (Array.isArray(q)) {
    //     setQuestions(q)
    //   }
    // } else if (data.choices[0].finish_reason !== 'stop' && data.choices[0].message.content) {
    //   setError(data.choices[0].message.content);
    // } else {
    //   setError('An error occurred while generating the document. Please try again.');
    // }

    // Directly generate the document formatted in a way that can be displayed in the UI
    
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${openAIKey}`,
      },
      body: JSON.stringify({
        model: "gpt-4o-mini",
        messages: [
          { role: "system", content: "You are a helpful assistant." },
          {
            role: "user",
            content: `Generate a legal document for ${document} in ${city}. Return response as JSON format with keys "document" , "questionSections" and "documentTitle"."document" should contain markdown formatted content for the document. "questionSections" should be an array of json objects like ["sectionTitle":"sectionTitle", [{"question": "question", "type": "type", "placeholder":"placeholder"}]]. Type can be "textinput", "checkbox" or "textarea". maximum "questions" are 5 and each "section" in it could contain maximum 5 question. "documentTitle" should be "text"`,
          },
        ],
        max_tokens: 1400,
      }),
    });

    const data = await response.json();

    if (
      data.choices &&
      data.choices[0].message &&
      data.choices[0].message.content
    ) {
      console.log("data.choices: " + data.choices);
      console.log("data.choices[0].message: ", data.choices[0].message);
      console.log(
        "data.choices[0].message.content: ",
        data.choices[0].message.content
      );
      try {
        // Clean and parse the JSON response
        const rawContent = data.choices[0].message.content
          .replace(/```json|```|```markdown/g, "")
          .replace(/^[^{]*/, "")
          .trim();

        const parsedResponse = JSON.parse(rawContent);

        if (parsedResponse.document)
          setGeneratedDocument(parsedResponse.document);
        if (parsedResponse.documentTitle)
          setDocumentTitle(JSON.stringify(parsedResponse.documentTitle));
        if (parsedResponse.questionSections) {
          setQuestionSections(parsedResponse.questionSections);
          console.log(
            "questionSections",
            JSON.stringify(parsedResponse.questionSections)
          );
          setFormData([]);
          navigate("/documents/form");
        }

        console.log("Generated Document:", parsedResponse.document);
      } catch (error) {
        console.error("Error parsing JSON response:", error);
        setError("An error occurred while processing the document.");
      }
    } else {
      setError(
        "An error occurred while generating the document. Please try again."
      );
    }

    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>AI Frank | Home</title>
        <meta name="description" content="" />
      </Helmet>

      <div
        data-id="container"
        className="w-full max-w-full overflow-x-hidden text-center text-[1.25rem] text-text-color font-poppins overflow-hidden "
      >
        <div
          data-id="top-glow"
          // [filter:blur(500px)] rounded-[50%] bg-primary-inside  border-stroked border-[1px] border-solid box-border w-[50rem] h-[30rem] mb-[-120px] mx-auto mt-[-20rem]
          className="top-glow "
        />
        <nav className="text-center md:text-left ml-[2.5rem]">
          <ImgLogo data-id="nav-logo" className="w-[8.5rem] h-[2rem]" />
        </nav>
        <div className="absolute top-4 right-0 mr-[2rem] hover:cursor-pointer">
        <button onClick={()=>{  
          console.log("logout")
          logout();
          navigate('/login');
        }} className="  mt-[1rem] hover:cursor-pointer  mr-[2rem] rounded-[30px]  bg-primary-inside border-stroked border-[1px] border-solid  hidden md:flex flex-row items-center justify-center py-[0.5rem] px-[2rem] text-text-color font-medium font-poppins ">
          {isAuthenticated ? "Logout" : "Login"}
        </button>
        {/* logout icon for smaller screens */}
        <div className="flex justify-center md:hidden hover:cursor-pointer " onClick={()=>{
          logout();
          navigate('/login');
        }}>
          <LogoutIcon className="w-[2rem] "/>
        </div>

        </div>
        <div className="flex flex-col items-center  justify-start text-[1.125rem] h-[calc(50vh)]  ">
          <ImgLineLeft
            data-id="line-left"
            className="relative self-start hidden md:block top-[28rem] left-[15rem] md:mb-0 rounded-xl w-[19.25rem] h-[8.625rem] object-contain"
          />

          <div className="rounded-[30px] mt-20 md:m-0  bg-primary-inside border-stroked border-[1px] border-solid flex flex-row items-center justify-center py-[0.5rem] px-[2rem]">
            <div className="relative tracking-[-0.04em] font-medium">
              AI For Documents
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-[2rem] md:text-[3.5rem] mt-[1rem]">
            <div className="self-stretch relative tracking-[-0.06em] font-medium">
              <span>Generate</span>
              <span className="text-white">{` `}</span>
              <span className="text-transparent !bg-clip-text [background:linear-gradient(91.15deg,_#707178,_#484950)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">{`Legal `}</span>
              <span>Documents</span>
            </div>
            <div className="self-stretch relative  text-[1.5rem] md:text-[1.25rem] tracking-[-0.04em] font-medium text-secondary-text mt-[0.5rem]">
              Legal documents Personalized for your needs.
            </div>
          </div>
          <ImgLineRight
            data-id="line-right"
            className="relative self-end hidden md:block md:top-[8rem] right-[15rem]  rounded-xl w-[19.25rem] h-[8.625rem] object-contain"
          />
        </div>

        <div className="max-w-[45rem] [backdrop-filter:blur(20px)] rounded-2xl bg-gray-500 border-gray-100 border-[1px] border-solid box-border flex flex-col items-center justify-center mx-[1rem] md:mx-auto p-[1.5rem] gap-[1rem] text-[0.875rem]  md:mt-[-2rem] text-stroked">
          {loading && (
            <PercentageIndicator
              show={loading}
              loadedPercentage={loadingPercentage}
              label="Generating Prerequisites"
              showPercent
            />
          )}
          <div
            className={`${
              loading ? "blur-md pointer-events-none " : ""
            }  max-w-[43.1875rem]  px-[1.5rem] `}
          >
            <div className="relative tracking-[-0.04em] font-medium mb-4">
              Available in the US only
            </div>
            <form
              onSubmit={handleGenerate}
              className="self-stretch flex flex-col items-center justify-start gap-[1.5rem] text-left text-[1.125rem] text-text-color z-1 "
            >
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem] ">
                <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />
                <div className="self-stretch flex flex-col md:flex-row items-center   overflow-hidden justify-start gap-[0.5rem] ">
                  <div className="max-w-[5.563rem] relative tracking-[-0.04em] font-medium flex items-center h-[2rem] shrink-0">
                    Generate
                  </div>
                  <div className="flex-1 w-[15rem] rounded-lg bg-gray-200 flex flex-row items-center justify-start py-[0.625rem] px-[1rem] text-[1rem] text-secondary-text">
                    <input
                      value={tempDocument}
                      onChange={(e) => {
                        setTempDocument(e.target.value);
                        setError("");
                      }}
                      className="font-medium font-poppins text-lg flex items-center h-[1.5rem] shrink-0 bg-transparent text-white outline-none"
                      placeholder="Document"
                    />
                  </div>
                  <div className="relative tracking-[-0.04em] font-medium flex items-center h-[2rem] shrink-0">
                    For
                  </div>
                  <div className="flex-1 w-[15rem] rounded-lg bg-gray-200 flex flex-row items-center justify-start py-[0.625rem] px-[1rem] text-[1rem] text-secondary-text">
                    <input
                      value={tempCity}
                      onChange={(e) => {
                        setTempCity(e.target.value);
                        setError("");
                      }}
                      className="font-medium font-poppins text-lg flex items-center h-[1.5rem] shrink-0 bg-transparent text-white outline-none"
                      placeholder="City"
                    />
                  </div>
                </div>
                <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />
              </div>
              <div className="self-stretch flex  items-center justify-center text-center text-[1rem]  ">
                <div className="flex flex-col items-center justify-center gap-[0.375rem]">
                  <button
                    disabled={loading}
                    className="flex-1 rounded-81xl bg-primary-inside border-stroked border-[1px] border-solid overflow-hidden flex flex-row items-center justify-center py-[0.5rem] pl-[1.5rem] pr-[2rem] gap-[0.25rem] outline-none hover:bg-primary-inside/40 transition-colors duration-300 cursor-pointer"
                  >
                    <ImgGenerate className="w-[1.5rem] relative h-[1.5rem] overflow-hidden shrink-0" />
                    <div className="font-medium text-[1rem] text-white font-poppins">
                      {loading ? "Generating..." : "Generate Form"}
                    </div>
                  </button>

                  {/* not present in figma */}
                  {/* <div style={{ display: loading ? 'none' : 'flex' }} className="flex-1 rounded-81xl bg-gray-200 border-darkslategray border-[1px] border-solid overflow-hidden flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] text-white cursor-pointer border-solid border-[1px] border-primary-inside hover:bg-primary-inside/40 transition-colors duration-300" onClick={() => { setTempCity(''); setTempDocument('') }}>
                    <div className="relative tracking-[-0.04em] font-medium ">Clear</div>
                  </div> */}
                   {error && (
                  <div className="text-red-500 align-center">{error}</div>
                )}
                </div>
               
                {/* <div className="hidden md:flex rounded-81xl bg-gray-200 border-darkslategray border-[1px] border-solid overflow-hidden flex-col items-center justify-center py-[0.5rem] px-[1.25rem]">
                  <div className="relative tracking-[-0.04em] font-medium">Share</div>
                </div> */}
              </div>
            </form>
          </div>
        </div>
       
       

        {/* {generatedDocument && <div ref={generatedDocumentRef} className="max-w-[45rem] [backdrop-filter:blur(20px)] rounded-2xl bg-gray-500 border-gray-100 border-[1px] border-solid box-border flex flex-col items-center justify-center mx-[1rem] md:mx-auto p-[1.5rem] gap-[1rem] text-[0.875rem] mt-[2rem] text-stroked text-left">
          <Markdown className="self-stretch relative tracking-[-0.04em] font-medium">{generatedDocument}</Markdown>
        </div>} */}

        <div className="flex flex-col  items-center justify-center gap-[1.5rem] mt-[6rem] relative ">
        <div
          data-id="middle-glow"
          className="middle-glow"
          // className="absolute right-0 left-0 [filter:blur(250px)] border border-stroked bg-primary-inside rounded-[48.75rem]  md:w-[48.75rem] max-w-screen h-[30.5625rem] mx-auto z-[-10]"
        />
          <p className="text-center text-[1.25rem] text-text-color font-poppins font-semibold leading-normal tracking-[-0.05rem]">
            AIFrank Document Samples
          </p>

          <div className="flex flex-col gap-8 mb-[6rem]">
            <InfiniteMovingCards
              items={documents}
              direction="left"
              speed="normal"
              onCardClick={handleCardClick}
            />

            <InfiniteMovingCards
              items={documents}
              direction="right"
              speed="normal"
              onCardClick={handleCardClick}
            />

           
          </div>
        </div>
      </div>
    </>
  );
}
