import { useEffect, useState } from "react";

interface PercentageIndicatorProps {
  loadedPercentage: number;
  show: boolean;
  showPercent?: boolean;
  label?: string;
}

export function PercentageIndicator({
  loadedPercentage = 0,
  show,
  showPercent = false,
  label,
}: PercentageIndicatorProps) {
  
  if (!show) return null;

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center bg-opacity-60 bg-gray-100 z-10">
      <div className="relative w-20 h-20">
        <svg className="w-full h-full transform -rotate-90">
          <circle
            cx="50%"
            cy="50%"
            r="28"
            stroke="currentColor"
            strokeWidth="5"
            className="text-gray-300"
            fill="transparent"
          />
          <circle
            cx="50%"
            cy="50%"
            r="28"
            stroke="#6280FB"
            strokeWidth="5"
            strokeDasharray="175.84"
            strokeDashoffset={175.84 - (175.84 * loadedPercentage) / 100}
            className="text-blue-500 transition-all duration-200"
            fill="transparent"
          />
        </svg>
        {showPercent && (
          <span className="absolute inset-0 flex items-center justify-center text-base font-semibold text-white">
            {loadedPercentage}%
          </span>
        )}
      </div>
      {label && (
        <div className="flex flex-row items-center">
          <h2 className="text-base font-normal text-white m-0">{label}</h2>
          <div className="flex flex-row space-x-1 ml-2 align-bottom">
            <span className="dot animate-dot1 bg-black rounded-full w-1.5 h-1.5"></span>
            <span className="dot animate-dot2 bg-black rounded-full w-1.5 h-1.5"></span>
            <span className="dot animate-dot3 bg-black rounded-full w-1.5 h-1.5"></span>
          </div>
        </div>
      )}
    </div>
  );
}
