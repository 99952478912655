import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { ReactComponent as ImgGenerate } from '../../assets/img/home/generate.svg';

import { ImgLineLeft,ImgLineRight , ImgLineHorizontal , ArrowDown , ImgLogo} from "../../assets";
import StepProgressBar from 'react-step-progress';
import { Step, Stepper } from 'react-form-stepper';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { PreparingDocument } from "../../components/progress/preparing-document.component";
import ProgressSteps from "../../components/progress/progress-steps.component";
import DocumentGenerator from "../../services/document-generator.service";
import { AppRoute } from "../../routes";
import { Nav } from "../../components/nav/nav";


interface Question {
  question: string;
  type: "textinput" | "textarea" | "checkbox";
  placeholder: string;
}

interface QuestionSection {
  sectionTitle: string;
  questions: Question[];
}


interface CompletedData {
  [sectionIndex: string]: { [questionIndex: string]: string | boolean };
}


export function FormPage() {

  const completedData: { [question: string]: any } = {};
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loaderFinish, setLoaderFinish] = useState(true);
  const appContext = useContext(AppContext);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const navigate = useNavigate();

  const {
    city,
    document,
    questionSections,
    documentTitle,
    formData,
    currentStep,
    setFormData,
    setGeneratedMarkdown,
    setCurrentStep,
    storedDocs,
    setStoredDocs,
  } = appContext!!;

  const handleInputChange = (questionIndex: number, value: string | boolean) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [currentStep]: {
        ...prevValues[currentStep],
        [questionIndex]: value,
      },
    }));
    setError('');
  };

 
  const handleNextStep = () => {
    let step = currentStep + 1;
    setCurrentStep(step);

    // Initialize form values for the next section if they haven't been set yet
    setFormData((prevValues) => ({
      ...prevValues,
      [currentStep + 1]: prevValues[currentStep + 1] || {}, // Only initialize if not already set
    }));
  };

  const handlePreviousStep = () => {
    let step = Math.max(currentStep - 1, 0);
    setCurrentStep(step);
    setError('');
  };

  const onFormSubmit = () => {
    questionSections?.forEach((section, sectionIndex: number) => {
      section.questions?.forEach((question: Question, questionIndex: number) => {
        // Check if formData[sectionIndex] and formData[sectionIndex][questionIndex] exist
        if (formData[sectionIndex] && formData[sectionIndex][questionIndex] !== undefined) {
          completedData[question.question] = formData[sectionIndex][questionIndex];
        }
      });
    });

    console.log('Completed data:', completedData);

    handleGenerate();
  };
  

  useEffect(() => {
    let innerTimeout: NodeJS.Timeout | null = null;
    let interval: NodeJS.Timeout | null = null;
    let currentStage = 0;
    let increment = 0;
    const stages = [0, 34, 67, 100]; // The percentage stages

    const updateLoading = () => {
      if (currentStage < stages.length) {
        const targetPercentage = stages[currentStage];

        interval = setInterval(() => {
          if (increment < targetPercentage) {
            increment += 1;
            setLoadingPercentage(increment);
          } else {
            currentStage++;
            if (interval) clearInterval(interval);

            if (currentStage < stages.length) {
              // After 2 seconds, move to the next stage
              innerTimeout = setTimeout(updateLoading, 1200);
            }
          }
          if (loadingPercentage > 98) {
            setLoaderFinish(true);
          }
        }, 50);
      }
    };

    if (loading) {
      updateLoading(); // Start loading stages when loading is true
      setLoaderFinish(false);
    }

    return () => {
      if (interval) clearInterval(interval); // Clear interval as well
    };
  }, [loading]);

  
  const handleGenerate = async () => {

    setError('');
  
    if (document.trim() === '' || city.trim() === '') {
      setError('Document and city are required');
      return;
    }
  
    const openAIKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!openAIKey) {
      setError('AI system down.. please retry after a few minutes');
      console.error('OpenAI API key is not set');
      return;
    }
  
    setLoading(true);
  
    try {

      const documentGenerator = new DocumentGenerator(openAIKey, document, city, completedData, );
      const generatedDocument = await documentGenerator.generateDocumentText();
      console.log('Generated document:', generatedDocument);
      setGeneratedMarkdown(generatedDocument);

      const generatedDoc = {
        id :     Date.now().toString(36) + Math.random().toString(36).substr(2, 9),
        document,
        city,
        documentTitle,
        formData,
        generatedMarkdown: generatedDocument,
        questionSections
      }

      console.log('Generated Doc', generatedDoc);
      setStoredDocs([...storedDocs, generatedDoc]);
  
      setCurrentStep(0);
      navigate('/documents/pdf');
    } catch (error) {
      console.error('Error generating document:', error);
      setError('An error occurred while generating the document. Please try again.');
    }
  
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>AI Frank | Home</title>
        <meta name="description" content="" />
      </Helmet>

      <div data-id="container" className="w-full text-center text-[1.25rem] text-text-color font-poppins overflow-hidden">
        <div data-id="top-glow" className="[filter:blur(500px)] rounded-[50%] bg-primary-inside border-stroked border-[1px] border-solid box-border w-[50rem] h-[30rem] mb-[-120px] mx-auto mt-[-20rem]" />
        <nav className="text-center md:text-left md:ml-[2.5rem] hover:cursor-pointer"  onClick={()=>navigate(AppRoute.Dashboard)}>
          <ImgLogo data-id="nav-logo" className="w-[8.5rem] h-[2rem] "/>
        </nav>
        <div className="flex flex-col justify-start text-[1.125rem] mx-[1rem] md:mx-[5rem] xl:mx-[10rem]">
          <ImgLineRight data-id="line-right" className="relative self-end hidden md:block md:top-[5rem]  rounded-xl w-[19.25rem] h-[8.625rem] object-contain" />
          <div className="rounded-[30px] self-center bg-primary-inside border-stroked border-[1px] border-solid flex flex-row items-center justify-center py-[0.5rem] px-[2rem] mt-[2rem] md:mt-0">
            <div className="relative tracking-[-0.04em] font-medium">AI Generated Form</div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-[2rem] md:text-[3.5rem] mt-[1rem]">
            <div className="self-stretch relative tracking-[-0.06em] font-medium">
              <span>{documentTitle && <div>{documentTitle}</div>}</span>
            </div>
            <div className="self-stretch relative text-[1.1rem] tracking-[-0.04em] font-medium text-white-text mt-[3rem]">Fill Up The Form</div>
            <ArrowDown className="self-center sm:block relative mb-[-10rem] rounded-xl w-[2rem] h-[2rem] object-contain justify-center border-solid border-[1px] border-stroked bg-gray-200" />
          </div>
          <ImgLineLeft data-id="line-left" className="relative hidden md:block top-[-3rem] left-[0rem] md:mb-0 rounded-xl w-[19.375rem] h-[8.625rem] object-contain" />
        </div>

        {/* <div data-id="middle-glow" className="[filter:blur(496.4px)] bg-stroked w-[17.438rem] h-[6.75rem] mx-auto z-0" /> */}

        {questionSections.length > 0 &&

          <div className="px-[1rem] md:px-[5rem] xl:p-[10rem] pb-[5rem]">
            <div className="w-full max-w-[130rem] mx-auto [backdrop-filter:blur(20px)] rounded-[24px] p-[12px] md:p-[24px] xl-p[40px] border-gray-100 border-[1px] border-solid box-border flex flex-col lg:flex-row items-center gap-[1rem] text-[0.875rem] mt-[4rem]">
              {(loading || !loaderFinish) &&
                <PreparingDocument
                  loadedPercentage={loadingPercentage}
                  show={loading || !loaderFinish}
                  loading={loading}
                />
              }
              <div className={`${loading || !loaderFinish ? 'blur-xl pointer-events-none' : ''} basis-2/6 [backdrop-filter:blur(20px)] flex flex-col items-center self-start gap-[1rem] lg:pl-[2rem] lg:p-[0.5rem] text-[0.875rem] text-stroked`} >
                <div className="text-secondary-text text-[1rem]">
                  <p>Step by Step Forms</p>
                </div>
                <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />

                <ProgressSteps
                  steps={questionSections.map((section, index) => ({
                    label: section.sectionTitle,
                    step: index + 1,
                  }))}
                  currentStep={currentStep + 1}
                  hideNavigation
                  direction="vertical"
                />
              </div>
              <div className={`${loading || !loaderFinish ? 'blur-xl pointer-events-none' : ''} basis-4/6  [backdrop-filter:blur(20px)] rounded-2xl bg-gray-500 border-gray-100 border-[1px] border-solid box-border flex flex-col items-center justify-center p-[0.8rem] md:p-[1.5rem] lg:p-[2rem] lg:m-[0.5rem] gap-[1rem] text-[0.875rem] text-stroked `}>
                <form className="self-stretch flex flex-col items-start justify-start gap-[1.5rem] min-h-[25rem]">
                  <div className="self-stretch justify-between flex flex-row">
                    <label className="text-[1.2rem] text-white">{questionSections[currentStep]?.sectionTitle}</label>
                    <label className="text-[1.2rem] text-white">{currentStep + 1}/{questionSections?.length}</label>
                  </div>
                  <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />
                  {questionSections[currentStep]?.questions?.map((questionObj, index) => (
                    <div key={index} className={`flex ${questionObj.type === 'checkbox' ? 'flex-row-reverse' : 'flex-col items-start self-stretch'} justify-start pr-[3rem]  gap-[0.5rem] md:gap-[1.5rem]`}>
                      <label className="relative tracking-[-0.04em] text-sm md:text-[1.1rem] font-small justify-start self-start text-left text-white">{questionObj.question}</label>
                      {questionObj.type === 'textinput' && (
                        <input
                          type="text"
                          placeholder={questionObj.placeholder}
                          value={
                            typeof formData[currentStep]?.[index] === 'string'
                              ? (formData[currentStep][index] as string)
                              : ''
                          }
                          onChange={(e) => handleInputChange(index, e.target.value)}
                          className="flex-1  rounded-md md:rounded-lg bg-gray-200 flex flex-row items-center justify-start p-[0.5rem] md:p-[1rem] text-sm md:text-[1rem] text-white w-full  focus:border-stroked focus:border-[1px] focus:border-solid focus:box-border"
                        />
                      )}
                      {questionObj.type === 'textarea' && (
                        <textarea
                          placeholder={questionObj.placeholder}
                          value={
                            typeof formData[currentStep]?.[index] === 'string'
                              ? (formData[currentStep][index] as string)
                              : ''
                          }
                          onChange={(e) => handleInputChange(index, e.target.value)}
                          className="rounded-lg bg-gray-200 flex flex-row items-center justify-start p-[1rem] text-[1rem] text-white h-[12rem] w-full"
                        />
                      )}
                      {questionObj.type === 'checkbox' && (
                        <input
                          type="checkbox"
                          checked={!!formData[currentStep]?.[index]} // `checked` expects a boolean
                          onChange={(e) => handleInputChange(index, e.target.checked)}
                          className="self-center appearance-none h-5 w-6 bg-gray-200 checked:bg-[#6381FC] rounded checked:before:content-['✓'] checked:before:text-white checked:before:block checked:before:text-center hover:cursor-pointer"
                        />
                      )}
                    </div>
                  ))}
                </form>
                <div className="flex self-end gap-[1rem] mt-[1rem]">
                  {currentStep > 0 && (
                    <button
                      onClick={handlePreviousStep}
                      className="rounded-xl bg-primary-inside px-[1.2rem] py-[0.5rem] text-white cursor-pointer border-solid border-[1px] border-primary-inside hover:bg-primary-inside/40 transition-colors duration-300"
                    >
                      Previous
                    </button>
                  )}

                  {currentStep < questionSections.length - 1 ? (
                    <button
                      onClick={handleNextStep}
                      className="rounded-xl bg-primary-inside px-[1.2rem] py-[0.5rem] text-white cursor-pointer border-solid border-[1px] border-stroked hover:bg-primary-inside/40 transition-colors duration-300"
                    >
                      Save & Continue
                    </button>
                  ) : (
                    <button
                      onClick={onFormSubmit}
                      className="rounded-xl bg-primary-inside px-[1.2rem] py-[0.5rem] text-white cursor-pointer border-solid border-[1px] border-stroked hover:bg-primary-inside/40 transition-colors duration-300"
                    >
                      Submit
                    </button>
                  )}
                </div>
                {error &&
                  <div>
                    <p className="text-red-500 text-[1rem]">{error}</p>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}
