import React, { useState, useEffect, forwardRef } from 'react';
import { marked } from 'marked';
import "../../assets/styles/document-preview.css";

const DocumentPreview = forwardRef<HTMLDivElement, { markdownContent: string }>(
    ({ markdownContent }, ref) => {
        const [htmlContent, setHtmlContent] = useState('');

        useEffect(() => {
            console.log('Markdown content:');
            try {
                const rawContent = markdownContent
                    .replace(/```json|```|```markdown|markdown/g, '')
                    .trim();

                const parseMarkdown = async () => {
                    const html = await marked(rawContent);
                    console.log('Parsed HTML:', html);
                    setHtmlContent(html);
                };

                parseMarkdown();
            } catch (error) {
                console.error('Error parsing markdown:', error);
                setHtmlContent('<p>Error loading document preview.</p>');
            }
        }, [markdownContent]);

        return (
            <div
                ref={ref} // Pass the ref here
                className="document-preview"
                dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
        );
    }
);

export default DocumentPreview;
