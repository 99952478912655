import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

import { ImgLogo , DeleteIcon, EditIcon , DownloadIcon , ImgLineHorizontal , ImgGenerate , ImgLineLeft ,ImgLineRight} from "../../assets";

import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import DocumentPreview from "../../components/pdf/document-preview";
import { useReactToPrint } from 'react-to-print';
import ProgressSteps from "../../components/progress/progress-steps.component";
import { AppRoute } from "../../routes";

export function PdfPage() {

  const [error, setError] = useState('');
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const documentRef = useRef<HTMLDivElement | null>(null);
  const [isReadyToPrint, setIsReadyToPrint] = useState(false);

  useEffect(() => {
      if (documentRef.current) {
          setIsReadyToPrint(true); // Set state to indicate that the document is ready
      
      }
  }, []);

  const handlePrint = useReactToPrint({
    contentRef : documentRef,
    pageStyle: `@page { size: A4; }`,
    documentTitle: `${appContext?.document} (AI Frank)`,
    onBeforePrint: async () => {
      console.log("Print job started");
      await new Promise((resolve) => setTimeout(resolve, 100)); // Small delay to ensure rendering completes
    },
    onAfterPrint: () => {
      console.log("Print job finished");
    },
  });
  


  const {
    questionSections,
    generatedMarkdown,
    currentStep,
    setCurrentStep,
    storedDocs,
    setStoredDocs,
    document,
    city,
    documentTitle,
    formData,

  } = appContext!!;

 
  

  const handleDownloadPdf = () => {
    console.log("Downloading PDF");
    console.log("ref", documentRef);
    setError('');
    handlePrint();
  };

  const handleEditForm = () => {
    navigate('/documents/form');
    setCurrentStep(0);
  };

  const handleDeleteForm = () => {
    localStorage.clear();
    navigate('/');
  }


  return (
    <>
      <Helmet>
        <title>AI Frank | Home</title>
        <meta name="description" content="" />
      </Helmet>

      <div data-id="container" className="w-full text-center text-[1.25rem] text-text-color font-poppins overflow-hidden">
        <div data-id="top-glow" className="[filter:blur(500px)] rounded-[50%] bg-primary-inside border-stroked border-[1px] border-solid box-border w-[50rem] h-[30rem] mb-[-120px] mx-auto mt-[-20rem]" />
        <nav className="text-center md:text-left md:ml-[2.5rem]" onClick={()=>navigate(AppRoute.Dashboard)}>
          <ImgLogo data-id="nav-logo" className="w-[8.5rem] h-[2rem]" />
        </nav>
        {/* <div className="flex flex-col justify-start text-[1.125rem] mx-[5rem]">
          <ImgLineRight data-id="line-right" className="relative self-end hidden md:block md:top-[3rem]  rounded-xl w-[19.25rem] h-[6.625rem] object-contain" />
          <div className="rounded-[30px] self-center bg-primary-inside border-stroked border-[1px] border-solid flex flex-row items-center justify-center py-[0.5rem] px-[2rem]">
            <div className="relative tracking-[-0.04em] font-medium">AI Generated Document</div>
          </div>

          <ImgLineLeft data-id="line-left" className="relative hidden md:block top-[-3rem] left-[0rem] md:mb-0 rounded-xl w-[19.375rem] h-[6.625rem] object-contain" />
        </div> */}
        {questionSections.length > 0 &&
          <div className="px-[1rem] md:px-[3rem] xl:px-[10rem] py-[3rem]">
            <div className="w-full max-w-[130rem] mx-auto mb-[5rem] [backdrop-filter:blur(20px)] rounded-[24px] p-[1rem] md:p-[24px] border-gray-100 border-[1px] border-solid box-border flex flex-col lg:flex-row  gap-[1rem] xl:gap-[2rem] text-[0.875rem] lg:mt-[4rem] xl:p-[2rem]">
              <div className="basis-2/6 [backdrop-filter:blur(20px)] rounded-2xl bg-gray-500 border-gray-100 border-[1px] border-solid box-border flex flex-col self-start p-[1rem] md:p-[1.5rem] gap-[1rem] text-[0.875rem] text-stroked h-full xl:px-[2rem]">
                <div className="text-white text-[20px] self-start">
                  <p>Options</p>
                </div>
                <div
                  className="rounded-xl bg-primary-inside px-[1.2rem] py-[0.5rem] cursor-pointer border-solid border-[1px] border-stroked hover:bg-primary-inside/40 transition-colors duration-300"
                  onClick={handleDownloadPdf} 
                >
                  <DownloadIcon data-id="download-icon" className="w-[24px] h-[24px] align-bottom" />
                  <button
                    className="bg-transparent text-white text-[16px] cursor-pointer"
                  >
                    Download PDF
                  </button>
                </div>
                <div
                  className="rounded-xl bg-primary-inside px-[1.2rem] py-[0.5rem] cursor-pointer border-solid border-[1px] border-stroked hover:bg-primary-inside/40 transition-colors duration-300"
                  onClick={handleEditForm}
                >
                  <EditIcon data-id="edit-icon" className="w-[24px] h-[24px] align-bottom" />
                  <button
                    className="bg-transparent text-white text-[16px] cursor-pointer"
                  >
                    Edit Document
                  </button>
                </div>
                <div
                  className="rounded-xl bg-transparent px-[1.2rem] py-[0.5rem] cursor-pointer border-solid border-[1px] border-[#E25354] hover:bg-[#E25354]/15 transition-colors duration-300"
                  onClick={handleDeleteForm}
                >
                  <DeleteIcon data-id="delete-icon" className="w-[24px] h-[24px] align-bottom" />
                  <button
                    className="bg-transparent text-[#E25354] text-[16px] cursor-pointer"
                  >
                    Delete Form
                  </button>
                </div>
                <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />
                <div className="text-white text-[20px] self-start">
                  <p>Steps Completed</p>
                </div>
                <ProgressSteps
                  steps={questionSections.map((section, index) => ({
                    label: section.sectionTitle,
                    step: index + 1,
                  }))}
                  currentStep={questionSections.length}
                  hideNavigation
                  direction="vertical"
                  documentCompleted
                />
              </div>
              <div className="basis-4/6  [backdrop-filter:blur(20px)] rounded-2xl bg-gray-500 border-gray-100 border-[1px] border-solid box-border flex flex-col items-center justify-start p-[1rem] md:p-[1.5rem] gap-[1rem] xl:p-[2rem]  text-[0.875rem] text-stroked">
                <div className="rounded-lg bg-primary-inside self-stretch px-[20px] py-[16px] text-white text-[20px]">
                  <p className="text-start m-0">Preview Your AI Generated Document</p>
                </div>
                <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />
                <div className="max-h-[40rem] lg:max-h-[40rem] overflow-y-scroll scrollbar-hide rounded-lg w-[100%]">
                  <DocumentPreview ref={documentRef} markdownContent={generatedMarkdown} />
                </div>
                {error &&
                  <div>
                    <p className="text-red-500 text-[1rem]">{error}</p>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}

